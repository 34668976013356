import getBreakPoint from '../helpers/breakpoints'
import debounce from '../helpers/debounce';
import {initDropdowns} from "flowbite";

document.addEventListener("livewire:navigated", () => {
    initNavigation();
    initActiveLinks();
})

const initNavigation = () => {

    const handleResize = () => {
        if (["sm", "default"].includes(getBreakPoint())) {
            renderMobileNavbar();
        } else {
            renderDesktopNavbar();
        }
    }

    const debouncedHandleResize = debounce(handleResize, 250);

    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    const $button = $('[data-collapse-navbar-button]');
    const $check = $('[data-collapse-navbar-checkbox]');
    const $el = $('[data-collapse-navbar]');

    $button.on('click', () => {
        if ($check.is(":checked")) {
            $el.addClass('navbar-collapse');
        } else {
            $el.removeClass('navbar-collapse');
        }
    })

    let navbarOpacity = () => {
        let scrollTop = window.pageYOffset || document
            .documentElement.scrollTop;
        if (scrollTop < 10) {
            $el.addClass("navbar-transparent");
        } else {
            $el.removeClass("navbar-transparent");
        }
    }

    window.addEventListener("scroll", function () {
        navbarOpacity()
    });

    navbarOpacity()
}

const initActiveLinks = () => {
    const currentPath = window.location.pathname;

    let origin = false;
    $('[data-active-link]').map((i, link) => {
        if(!origin) {
            let uri = link.getAttribute('data-active-link');
            if (link) {

                if(currentPath === '/') {
                    origin = true;
                    link.classList.add('active');
                }

                if(uri !== '/') {
                    if(!origin) {
                        if (currentPath.startsWith(uri)) {
                            link.classList.add('active');
                        } else {
                            link.classList.remove('active');
                        }
                    }
                }
            }
        }
    });
};

// Утилитарные функции
const createDateFromString = (dateStr) => {
    return new Date(dateStr.split('.').reverse().join('-'));
};

const isWithinLastWeek = (dateStr) => {
    const today = new Date();
    const itemDate = createDateFromString(dateStr);
    return today < new Date(itemDate.setDate(itemDate.getDate() + 7));
};

const createBadge = (isAnimated = false, extraClasses = '') => {
    const baseClasses = 'badge badge-primary';
    const classes = [
        baseClasses,
        isAnimated ? 'badge-animate' : '',
        extraClasses
    ].filter(Boolean).join(' ');

    return $('<div>', { class: classes });
};

// Создание элементов меню
const createMenuItem = (item) => {
    const $li = $('<li>');
    const $a = createMenuLink(item);

    if(item.disabled) {
        $a.addClass('disabled');
        $a.attr('data-tippy', '');
        $a.attr('data-tippy-content', 'Отключено администратором');
    }

    if (item.icon) {
        $a.prepend($(item.icon).addClass('h-5 w-5'));
    }

    $a.append($('<span>').text(item.label));

    if (item.date && isWithinLastWeek(item.date)) {
        $a.append(createBadge(true, 'badge-xs'));
    }

    return $li.append($a);
};

const createMenuLink = (item) => {
    const $a = $('<a>');

    $a.attr('data-active-link', item.active);

    if (item.items) {
        const dropdownId = `dropdown-${Math.random().toString(36).substring(2, 12)}`;
        Object.assign($a[0].dataset, {
            dropdownToggle: dropdownId,
            dropdownPlacement: 'bottom',
            dropdownOffsetDistance: '20'
        });
        $a.attr('type', 'button');
    }

    if (item.route || item.link) {
        $a.attr('href', item.link);
        if (item.blank) {
            $a.attr('target', '_blank');
        } else {
            $a.attr('wire:navigate', '');
        }
    }

    return $a;
};

// Создание подменю
const createSubmenuItem = (subItem) => {
    const $li = $('<li>');
    const $a = createSubmenuLink(subItem);
    const $info = createSubmenuInfo(subItem);

    if (subItem.icon) {
        $a.prepend($(subItem.icon).addClass('h-10 w-10 shrink-0'));
    }

    if(subItem.disabled) {
        $a.addClass('disabled');
        $a.attr('data-tippy', '');
        $a.attr('data-tippy-content', 'Отключено администратором');
    }

    $a.append($info);

    if (subItem.date && isWithinLastWeek(subItem.date)) {
        $a.addClass('border border-primary/10')
        $a.append(createBadge(true, 'absolute -top-2 -right-2'));
    }

    return $li.append($a);
};

const createSubmenuLink = (subItem) => {
    const $a = $('<a>', { class: 'mega-menu-item' });

    $a.attr('data-active-link', subItem.active);

    if (subItem.items) {
        $a.attr('data-collapse-toggle',
            `${subItem.label.toLowerCase().replace(/ /g, '-')}-dropdown`
        );
    }

    if (subItem.route || subItem.link) {
        $a.attr('href', subItem.link);
        if (subItem.blank) {
            $a.attr('target', '_blank');
        } else {
            $a.attr('wire:navigate', '');
        }
    }

    return $a;
};

const createSubmenuInfo = (subItem) => {
    const $info = $('<div>');

    $info.append(
        $('<div>', {
            class: 'font-semibold text-lg leading-7',
            text: subItem.label
        })
    );

    if (subItem.description) {
        $info.append(
            $('<p>', {
                class: 'text-sm leading-4 text-white/50',
                text: subItem.description
            })
        );
    }

    return $info;
};

// Основная функция рендеринга
const renderDesktopNavbar = () => {
    $('[data-menu-inner]').html('');
    $('[data-menu-collapse-inner]').html('');
    const $menu = $('<div>', {
        class: 'menu menu--navbar menu-horizontal px-1'
    }).appendTo('[data-menu-inner]');

    window.menu.forEach(item => {
        if(item) {
            const $menuItem = createMenuItem(item);
            $menu.append($menuItem);

            if (item.items) {
                const dropdownId = $menuItem.find('[data-dropdown-toggle]').data('dropdown-toggle');
                const $dropdown = $('<div>', {
                    id: dropdownId,
                    class: 'mega-menu hidden'
                });

                const $container = $('<div>', {
                    class: 'container px-4 mx-auto flex justify-center'
                });

                const $ul = $('<ul>', { class: 'mega-menu-container' });

                item.items.forEach(subItem => {
                    if(subItem) {
                        $ul.append(createSubmenuItem(subItem));
                    }
                });

                $container.append($ul);
                $dropdown.append($container);
                $dropdown.appendTo('[data-menu-inner]');
            }
        }
    });

    initDropdowns();
};

const renderMobileNavbar = () => {
    $('[data-menu-inner]').html('');
    $('[data-menu-collapse-inner]').html('');
    const $menu = $('<div>', {
        class: 'menu menu--navbar menu-vertical px-1'
    }).appendTo('[data-menu-collapse-inner]');

    window.menu.forEach(item => {
        if(item) {
            if (item.items) {
                item.items.forEach(subItem => {
                    $menu.append(createMenuItem(subItem));
                });
            } else {
                const $menuItem = createMenuItem(item);
                $menu.append($menuItem);
            }
        }
    });
};
