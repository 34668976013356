/**
 * Создает функцию debounce, которая откладывает вызов функции func
 * до тех пор, пока не пройдет указанное количество миллисекунд wait
 * с момента последнего вызова функции debounce.
 *
 * @param {Function} func - Функция, которую нужно обернуть в debounce
 * @param {number} wait - Задержка в миллисекундах
 * @param {boolean} [immediate] - Вызывать функцию сразу при первом вызове (по умолчанию false)
 * @returns {Function} Возвращает новую функцию с debounce
 */
export function debounce(func, wait, immediate = false) {
    let timeout;

    return function executedFunction(...args) {
        const context = this;

        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

export default debounce;
