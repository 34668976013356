class ServerSelect {

    constructor() {
        this.card = $('[data-server-select-card]')
        this.cards = $('[data-server-select-cards]')
        this.inputSelect = $('[data-server-select]');
        this.btnClose = $('[data-server-select-close]');
        this.btnAll = $('[data-server-select-all]');
        this.btnSubmit = $('[data-server-select-submit]');
        this.inner = $('[data-server-select-inner]');

        this.multiple = this.inputSelect.prop('multiple');
        this.required = this.inputSelect.prop('required')

        this.selected = [];
        this.inputSelect.children('option').each((i, el) => {
            if(el.selected && el.value !== 'none') {
                this.selected.push(el.value)
            }
        });

        this.inner.html(
            this.innerTemplate()
        );
    }

    init() {
        this.apply();
        this.card.on('click', e => this.toggle(e))
        this.btnSubmit.on('click', e => this.dispatch())
        this.btnAll.on('click', e => this.all())
    }

    all() {
        this.selected = []
        this.cards.children('[data-server-select-card]').each((i, el) => {
            let slug = this.slug($(el))
            this.selected.push(slug)
        })
        this.apply()
    }

    toggle(e) {
        if(!this.multiple) {
            if(this.selected.length >= 1) {
                this.selected = [];
            }
        }
        let $el = $(e.currentTarget);
        let sid = this.slug($el)
        let find = this.selected.find(id => id === sid);
        if(find) {
            this.remove(sid, $el)
        } else {
            this.add(sid, $el)
        }

        if(!this.multiple) {
            this.dispatch();
        }
    }

    add(sid, $el) {
        this.selected.push(sid)
        this.apply()
    }

    remove(sid, $el) {
        if(this.selected.length !== 1 || this.required === false) {
            let i = this.selected.findIndex(id => id === sid)
            this.selected.splice(i, 1)
            this.apply()
        }
    }

    apply() {
        this.cards.children('[data-server-select-card]').each((i, el) => {
            let id = this.slug($(el))
            let option = this.inputSelect.children('[value='+id+']');
            window.myOption = option;
            if(this.selected.find(i => i === id)) {
                $(el).addClass('selected');
                option.attr('selected', true);
            } else {
                $(el).removeClass('selected');
                option.removeAttr('selected');
            }
        })
    }

    dispatch() {
        this.inner.html(
            this.innerTemplate()
        );
        Livewire.dispatch('selectUpdated', [this.selected])
        this.btnClose.trigger('click')
    }

    innerTemplate () {
        if(this.selected.length === 0) {
            return 'Выберите сервер'
        } else if(this.selected.length === 1) {
            let server = getServerByQuery(this.selected[0]);
            return `${server.name}
            <div class="badge badge-sm" data-server-title="${server.id}" style="background-color: rgb(var(--server-${server.slug}))"></div>`
        } else {
            let content = '<span>Несколько серверов</span>';
            this.selected.map(id => {
                let server = getServerByQuery(id);
                content += `<div class="badge badge-sm" data-server-title="${server.id}" style="background-color: rgb(var(--server-${server.slug}))"></div>`
            })
            return content;
        }
    }

    slug(el) {
        return el.data('server-slug')
    }
}

document.addEventListener("livewire:navigated", () => {
    let serverSelect = new ServerSelect();
    serverSelect.init();
})
