
const serverCard = ($el, query, type) => {
    let server = getServerByQuery(query);

    tippy($el[0], {
        allowHTML: true,
        theme: 'union',
        content: serverCardTemplate(server),
    });
}

const serverCardTemplate = (server) => {
    return `
<div class="h-8 flex flex-row gap-3 items-center px-2">
    <span class="whitespace-nowrap">${server.name}</span>
    <div class="badge badge-sm" style="background-color: rgb(var(--server-${server.slug}))"></div>
</div>
    `;
}

const getServerByQuery = query => {
    if(typeof query === "number") {
        return window.servers.find((el) => el.id === query);
    } else if(typeof query === "string") {
        return window.servers.find((el) => el.slug === query);
    } else {
        return false;
    }
}

document.addEventListener('livewire:navigated', () => {
    window.getServerByQuery = getServerByQuery;
    let body = $('body');
    body.on('mouseenter', '[data-server-title]', (e) => {
        let $el = $(e.currentTarget);
        let serverId = $el.data('server-title')
        serverCard($el, serverId, 'title');
    });
})
