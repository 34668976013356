import Color from 'color'

document.addEventListener('DOMContentLoaded', (e) => {
    let colors
    let root = document.querySelector(':root');
    colors = window.servers.reduce((acc, item) => {
        let color = new Color(item.color);
        root.style.setProperty('--server-'+item.slug, color.rgb().array().join(', ') )
        root.style.setProperty('--server-'+item.slug+'-lighten', color.lighten(.5).rgb().array().join(', ') )
        root.style.setProperty('--server-'+item.slug+'-darken', color.darken(.5).rgb().array().join(', ') )
        acc[item.slug] = color;
        return acc;
    }, {});
    window.serverColors = colors;
})
