
import getBreakPoint from '../helpers/breakpoints'
import imagesLoaded from "../helpers/imagesLoaded";

// $(document).ready(() => {
//     if(imagesLoaded($('.parallax')))
//     console.log("Image loaded succesfully!");
//     $(".parallax--overlay-loader").addClass('parallax--loaded');
// })

// imagesLoaded(document.getElementsByClassName('.parallax'), () => {
//     $(".parallax--overlay-loader").addClass('parallax--loaded');
// })

const initParallax = () => {

    window.enableParallax = true;

    let checkParallax = () => {
        window.enableParallax = ['lg', 'xl'].includes(getBreakPoint());

        if(!window.enableParallax) {
            $(".parallax--image-play").css({
                transform: 'none'
            });
        }
    }

    imagesLoaded(document.getElementById('parallax'), () => {
        $(".parallax--overlay-loader").addClass('parallax--loaded');
    })

    $(window).on('resize', () => {
        checkParallax()
    })

    checkParallax();

    let parallax = ($el, resistance, mouse) => {
        if(window.enableParallax) {
            TweenLite.to($el, 0.2, {
                x: -((mouse.clientX - window.innerWidth / 2) / resistance),
                y: -((mouse.clientY - window.innerHeight / 2) / resistance)
            });
        }
    };

    $(window).mousemove((e) => {
        parallax($(".parallax--image-bg"), 40, e);
        parallax($(".parallax--image-forest"), 30, e);
        parallax($(".parallax--image-brand"), 25, e);
        parallax($(".parallax--image-fog"), 0, e);
        parallax($(".parallax--image-car"), 20, e);
        parallax($(".parallax--image-foliage"), 10, e);
        // parallax($(".parallax--image-play"), 5, e);
    });
}

document.addEventListener("livewire:navigated", () => {
    if(document.getElementById('parallax')) {
        initParallax();
    }
})
