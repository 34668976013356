import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const lightbox = new PhotoSwipeLightbox({
    gallery: '#home-gallery',
    children: 'a',
    showHideAnimationType: 'fade',
    pswpModule: () => import('photoswipe')
});

document.addEventListener("livewire:navigated", () => {
    lightbox.init();
})
