document.addEventListener("livewire:navigated", () => {
    $('body').on('mouseenter', '[data-tippy]', function(e) {
        const element = e.currentTarget;

        // Проверяем существующий тултип и обновляем контент при необходимости
        if (element._tippy) {
            const currentContent = element._tippy.props.content;
            const newContent = element.getAttribute('data-tippy-content');

            if (currentContent !== newContent) {
                element._tippy.destroy();
            } else {
                return;
            }
        }

        // Создаем новый тултип, если он не существует
        if (!element._tippy) {
            // Базовые настройки
            const tippyConfig = {
                theme: 'union'
            };

            // Получаем и добавляем содержимое
            if (element.hasAttribute('data-tippy-content')) {
                tippyConfig.content = element.getAttribute('data-tippy-content');
            }

            // Добавляем задержку (delay)
            if (element.hasAttribute('data-tippy-delay')) {
                const delayValue = element.getAttribute('data-tippy-delay');
                // Проверяем, если delay содержит запятую, значит это массив [show, hide]
                if (delayValue.includes(',')) {
                    const [showDelay, hideDelay] = delayValue.split(',').map(val => parseInt(val.trim()));
                    tippyConfig.delay = [showDelay, hideDelay];
                } else {
                    tippyConfig.delay = parseInt(delayValue);
                }
            }

            // Интерактивность
            if (element.hasAttribute('data-tippy-interactive')) {
                tippyConfig.interactive = element.getAttribute('data-tippy-interactive') === 'true';
            }

            // Положение (placement)
            if (element.hasAttribute('data-tippy-placement')) {
                tippyConfig.placement = element.getAttribute('data-tippy-placement');
            }

            // Длительность анимации
            if (element.hasAttribute('data-tippy-duration')) {
                const durationValue = element.getAttribute('data-tippy-duration');
                if (durationValue.includes(',')) {
                    const [showDuration, hideDuration] = durationValue.split(',').map(val => parseInt(val.trim()));
                    tippyConfig.duration = [showDuration, hideDuration];
                } else {
                    tippyConfig.duration = parseInt(durationValue);
                }
            }

            // Триггер (hover, click, focus и др.)
            if (element.hasAttribute('data-tippy-trigger')) {
                tippyConfig.trigger = element.getAttribute('data-tippy-trigger');
            }

            // Максимальная ширина
            if (element.hasAttribute('data-tippy-maxWidth')) {
                tippyConfig.maxWidth = element.getAttribute('data-tippy-maxWidth');
            }

            // Смещение
            if (element.hasAttribute('data-tippy-offset')) {
                const offsetValue = element.getAttribute('data-tippy-offset');
                if (offsetValue.includes(',')) {
                    tippyConfig.offset = offsetValue.split(',').map(val => parseInt(val.trim()));
                } else {
                    tippyConfig.offset = parseInt(offsetValue);
                }
            }

            // Следование за курсором
            if (element.hasAttribute('data-tippy-followCursor')) {
                const followValue = element.getAttribute('data-tippy-followCursor');
                if (followValue === 'true' || followValue === 'false') {
                    tippyConfig.followCursor = followValue === 'true';
                } else {
                    tippyConfig.followCursor = followValue; // initial, horizontal, vertical
                }
            }

            tippy(element, tippyConfig);
        }
    });
});

/*
    data-tippy - создание тултипа
    data-tippy-content - содержимое тултипа
    data-tippy-delay - задержка показа/скрытия (мс)
    data-tippy-interactive - интерактивность (true/false)
    data-tippy-placement - положение (top, bottom, left, right...)
    data-tippy-duration - длительность анимации
    data-tippy-trigger - событие для активации (hover, click, focus...)
    data-tippy-maxWidth - максимальная ширина
    data-tippy-offset - смещение
    data-tippy-followCursor - следование за курсором
* */
