// data-banner-image
document.addEventListener("livewire:navigated", () => {
    initBannerUpdate();
})

const initBannerUpdate = () => {
    $('body').on('click', '[data-banner-image]', (e) => {
        let $inner = $('[data-banner-inner]');
        $inner.attr('src', $(e.currentTarget).data('banner-image'));
        $inner.removeClass('hidden');
        $inner.attr('srcset', '');
    })
}
