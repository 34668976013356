import countryFlagEmoji from "country-flag-emoji";

document.addEventListener("livewire:navigated", () => {
    $('[data-emoji-country]').each((i, el) => {
        let $el = $(el);
        let code = $el.data('emoji-country')
        let emoji = countryFlagEmoji.get(code)
        if(code !== undefined && emoji) {
            $el.html(emoji.emoji);
            $el.attr('data-tippy', '');
            $el.attr('data-tippy-content', emoji.name);
        }
    })
});
