import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import ToastComponent from '../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts'
import Clipboard from "@ryangjchandler/alpine-clipboard"
import Tooltip from "@ryangjchandler/alpine-tooltip"

import {initDropdowns} from "flowbite";

Alpine.plugin(ToastComponent)
Alpine.plugin(Clipboard)
Alpine.plugin(Tooltip.defaultProps({
    theme: 'union'
}))

Livewire.start()

document.addEventListener("livewire:navigated", () => {
    initDropdowns();
});
